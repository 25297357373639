
import FetchRequest from "@/api/FetchRequest";
class AivoiceApi {
    //基础urlai角色
    static url = "/vim/voice/changer";
    static urlVoice = "/vim/voice/change";
    /**
 * 获取角色查询列表
 * @param pageNum 
 * @param pageSize 
 */
    static getList(pageNum: number, pageSize: number): Promise<any> {

        return FetchRequest.get(this.url + '/role/list' + '?pageNum=' + pageNum + '&pageSize=' + pageSize, true);
    }
    /*
    新增角色
    */
    static addRole(avatar: any, name: string, voiceFile: any): Promise<any> {
        const data = {
            avatar: avatar,//头像
            name: name,
            voiceFile: voiceFile,//训练素材
        }
        return FetchRequest.post(this.url + '/role' + '?avatar=' + avatar + '&name=' + name + '&voiceFile=' + voiceFile, '', true);
    }
    static addRoles(name: string, formData: any): Promise<any> {
        // const data={
        //     avatar:avatar,//头像
        //     name: name,
        //     voiceFile:voiceFile,//训练素材
        // }
        return FetchRequest.post(this.url + '/role' + '?name=' + name, JSON.stringify(formData), true);
    }
    /**
 * 获取上传音频列表
 *
 * @param pageNum
 * @param pageSize
 */
    static getChangeList(pageNum: number, pageSize: number): Promise<any> {

        return FetchRequest.get(this.urlVoice + '/list' + '?pageNum=' + pageNum + '&pageSize=' + pageSize, true);
    }

    /**
     * 删除上传音频
     
     */
    static delChangeVoice(roleId: string, ): Promise<any> {
        return FetchRequest.del('/vim/voice/change/' + roleId, '', true);
    }
    /**
     * 删除上传音频
     *
     */
    static toChangeVoice(voiceId: string, roleId: string): Promise<any> {

        return FetchRequest.post(this.urlVoice + '/go/' + voiceId + '/' + roleId, '', true);
    }
    // 删除角色
    static delVoicerole(roleId: string, path: string): Promise<any> {
        return FetchRequest.del(this.url + '/role/' + roleId, '', true);
    }
    // 角色训练
    static trainRoles(roleId: string, epoch: number,samplingRate: number,tone:number): Promise<any> {
        const data={
            epoch:epoch,
            samplingRate: samplingRate,
            tone:tone,
        }
        return FetchRequest.post(this.url + '/role/train/' + roleId, JSON.stringify(data), true);
    }
    // 变声
    static voiceChange(voiceId: string,roleId:string, duration: number,gainVc: number,indexrate:number, protect: number,slow: number,volume:number): Promise<any> {
        const data={
            duration:duration,
            gainVc: gainVc,
            indexrate:indexrate,
            protect:protect,
            slow: slow,
            volume:volume,
        }
        return FetchRequest.post('/vim/voice/change/go/' + voiceId+'/'+roleId, JSON.stringify(data), true);
    }


}
export default AivoiceApi;